import { JSONCache } from 'services/cache'

class TokenCache extends JSONCache {
  constructor(storage) {
    super(`little-book/token`, storage)
  }
}

const instance = new TokenCache(localStorage)
export default instance
