import customerApi from 'services/api/customer'
import demoApi from 'services/api/demo'
import tokenCache from 'services/cache/token'
import queryString from 'query-string'

function Authentication(props) {
  const { token } = queryString.parse(props.history.location.search)
  if (token) {
    tokenCache.set(token)
    demoApi.setAuthToken({authToken: token})
    customerApi.setAuthToken({authToken: token})
  }
  return props.children
}

function deauthenticate () {
  tokenCache.remove()
  demoApi.setAuthToken({authToken: ''})
  customerApi.setAuthToken({authToken: ''})
}

export default Authentication
export {
  deauthenticate
}
