import React from 'react'
import {Provider} from 'react-redux'
import Loadable from 'react-loadable'
import {Router, Switch, Route, Redirect} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import {LocaleProvider,Layout} from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'

import {APP_NAME, APP_VERSION, APP_DESCRIPTION, APP_SHA} from 'services/constants'
import store from 'services/store'
import history from 'services/history'
import useFlagrNotification from 'hooks/useFlagrNotification'

import Authentication from './components/Authentication'


import styles from './styles.module.scss'

const {
  Header, Footer, Content,
} = Layout

const Nav = Loadable({
  loading: () => null,
  loader: () => import('scenes/Nav')
})

const Customers = Loadable({
  loading: () => null,
  loader: () => import('scenes/Customers')
})

const Customer = Loadable({
  loading: () => null,
  loader: () => import('scenes/Customer')
})

const Demos = Loadable({
  loading: () => null,
  loader: () => import('scenes/Demos')
})

const Stats = Loadable({
  loading: () => null,
  loader: () => import('scenes/Stats')
})

const ExpiringCustomers = Loadable({
  loading: () => null,
  loader: () => import('scenes/ExpiringCustomers')
})

function App() {
  useFlagrNotification()
  return (
    <LocaleProvider locale={locale} >
      <Provider store={store} >
        <Router history={history} >
          <Authentication history={history}>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
              />
              <title>小本本</title>
            </Helmet>
            <Layout>
              <Header>
                <Nav
                  history={history}
                />
              </Header>
              <Content className={styles.content}>
                <Switch>
                  <Route
                    path="/customers"
                    exact={true}
                    component={Customers}
                  />
                  <Route
                    path="/customer/:id/:tab"
                    exact={true}
                    component={Customer}
                  />
                  <Route
                    path="/stats"
                    exact={true}
                    component={Stats}
                  />
                  <Route
                    path="/demos"
                    exact={true}
                    component={Demos}
                  />
                  <Route
                    path="/expiring-customers/:tab"
                    exact={true}
                    component={ExpiringCustomers}
                  />
                  <Redirect from="/" to="/customers" />
                </Switch>
              </Content>
              <Footer>
              </Footer>
            </Layout>
          </Authentication>
        </Router>
      </Provider>
    </LocaleProvider>
  )
}

export default App
