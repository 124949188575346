import { BaseApi } from './BaseApi'
import tokenCache from 'services/cache/token'

class DemoApi extends BaseApi {
  getAll() {
    return this.request.get('/demos')
  }

  /**
   * @param {string} shopId shopId
   */
  async getVisitor(shopId) {
    const { data } = await this.request.post(`/demos/${shopId}`)
    return data
  }

  async getLxkVisitor(shopId, type) {
    const { data } = await this.request.post(`/demos/${shopId}/${type}`)
    return data
  }
}

const instance = new DemoApi({ authToken: tokenCache.get() })
export default instance
