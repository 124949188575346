import {SSO_ENDPOINT,FLASHCARD_SITE_NAME} from 'services/constants'
import history from 'services/history'

function createAuthURL() {
  return `${SSO_ENDPOINT}/sites/${FLASHCARD_SITE_NAME}/login?next=${encodeURIComponent(`${window.location.origin}${history.createHref({
    ...history.location,
    search: `?token={token}`
  })}`)}`
}

export default createAuthURL
