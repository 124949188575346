import { useEffect, useState } from 'react'
import { notification } from 'antd'
import { get } from 'lodash-es'
import Markdown from 'react-markdown'

import flagShip from 'services/flagr'

// TS 类型
// interface Note
//   extends Pick<
//     ArgsProps,
//     'message' | 'description' | 'duration' | 'placement'
//   > {
//   pathname?: string
//   type?: IconType
//   placement?: NotificationPlacement
// }

/** @return FlagrNotificationProps */
export default function useFlagrNotification() {
  const [notes, setNotes] = useState([])

  useEffect(() => {
    let cancel = false
    flagShip.prepare(() => ({ app: process.env.REACT_APP_NAME }))
    flagShip.tap().then(({ notification }) => {
      if (cancel) return
      const raw = get(notification, 'notes')
      setNotes(Array.isArray(raw) ? raw : [])
    })

    return () => {
      cancel = true
    }
  }, [])

  useEffect(() => {
    notes.forEach(({ type, description, ...rest }) => {
      notification[type]({
        description: description &&
          <Markdown
            linkTarget="_blank"
          >
            {description}
          </Markdown>,
        ...rest
      })
    })
  }, [notes])
}
