import { Executor, Flag } from '@leyan/flag-runner'
import { get } from 'lodash-es'

const varientKeyParser = (flag) => {
  return get(flag, 'applying.result.variantKey') !== undefined
}

const featureFlags = [
  {
    key: 'k4xijzshhn4ehxpp6',
    name: 'notification',
    parse: (flag) => get(flag, 'attachment') || {},
  },
]

class FlagShip {
  executor

  createContext

  static flags

  constructor() {
    FlagShip.flags = FlagShip.mapFlag(featureFlags)
    this.executor = new Executor({
      endpoint: process.env.REACT_APP_NTA_FLAGR || '',
      batchMode: true,
    })
  }

  prepare(create) {
    this.executor.setContextCreator(() => ({ entityContext: create() }))
  }

  async tap(
    featureNames = featureFlags.map(({ name }) => name)
  ) {
    const flags = FlagShip.mapFlag(
      featureFlags.filter(({ name }) =>
        featureNames.some((one) => one === name)
      )
    )
    try {
      const incomingFlags = await this.executor.execute(flags)
      const features = {}
      incomingFlags.forEach((flag) => {
        const { key } = flag
        const parse =
          featureFlags.find((one) => one.key === key)?.parse || varientKeyParser
        if (parse !== undefined) {
          features[flag.name] = parse(flag)
          return
        }
        features[flag.name] = false
      })
      return features
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      return {}
    }
  }

  static mapFlag = (featureFlags) => {
    return featureFlags.map(({ key, name }) => {
      const flag = new Flag({
        key,
        name,
      })
      return flag
    })
  }
}

const flagShip = new FlagShip()

export default flagShip
