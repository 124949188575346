import { BaseApi } from './BaseApi'
import tokenCache from 'services/cache/token'

class CustomerApi extends BaseApi {
  async getAll({ page = 1, field, content, status }) {
    const res = await this.request.get('/customers', {
      params: {
        page,
        field,
        content,
        status,
        show_unnotified_bugs: true,
      },
    })
    return res.data
  }

  async getOne(id, options) {
    const res = await this.request.get(`/customers/${id}`, options)
    return res.data
  }

  async getUsers() {
    const res = await this.request.get(`/users`)
    return res.data
  }

  async addShop({ url, customerId }) {
    let route = '/customers/new'
    if (customerId) {
      route = `/customers/${customerId}/shops/new`
    }
    const res = await this.request.post(route, {
      url,
    })
    return res.data
  }

  async update(customerId, options) {
    const res = await this.request.patch(`/customers/${customerId}`, options)
    return res.data
  }

  async updateUsers({ users, customerId }) {
    const route = `/customers/${customerId}/users`
    const res = await this.request.put(route, {
      users: users.map((u) => {
        return {
          id: u.id,
        }
      }),
      customerId,
    })
    return res.data
  }

  async getIssues(params) {
    const { customerId } = params
    const res = await this.request.get(`/customers/${customerId}/issues`, {
      params,
    })
    return res.data
  }

  async getZones() {
    const res = await this.request.get(`/zones`)
    return res.data
  }

  async closeIssue(id, taskType) {
    const res = await this.request.put(`/issues/${id}/close`, {
      task_type: taskType,
    })
    return res.data
  }

  async returnIssue(id, params = {}) {
    // 设置任务为验证不通过状态，同时指派任务给默认指派人。
    const res = await this.request.put(`/issues/${id}/return`, params)
    return res.data
  }

  async fetchStaffNeuronUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/staff_neuron`)
    return res.data
  }

  async fetchNeuronUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/neuron`)
    return res.data
  }

  async fetchNantianmenUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/nantianmen`)
    return res.data
  }

  async fetchTranscriptUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/transcript`)
    return res.data
  }

  async fetchLxkUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/lxk`)
    return res.data
  }

  async fetchLxkLiteUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/lxk-lite`)
    return res.data
  }

  async fetchCemUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/cem`)
    return res.data
  }

  async fetchZBUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/live`)
    return res.data
  }

  async fetchNewWorldUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/neuron-migrate`)
    return res.data
  }

  async fetchFassionUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/fassion`)
    return res.data
  }

  async fetchShops(params) {
    const res = await this.request.get('/shops', {
      params,
    })
    return res.data
  }

  async updateShopStatus(sid, status) {
    const res = await this.request.put(`/shops/${sid}/status`, {
      status,
    })
    return res.data
  }

  async fetchFishpondUrl(sid) {
    const res = await this.request.get(`/shops/${sid}/fishpond`)
    return res.data
  }

  async updateMiniAppShop(sid) {
    const res = await this.request.put(`/shops/${sid}/prg`)
    return res.data
  }

  async getMiniAppBuyLinks(sid) {
    const res = await this.request.get(`/shops/${sid}/mini_app_buy_links`)
    return res.data
  }
}

const instance = new CustomerApi({ authToken: tokenCache.get() })
export default instance
