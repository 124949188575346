export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME

export const FLASHCARD_API_ENDPOINT = process.env.REACT_APP_FLASHCARD_API_ENDPOINT
export const FLASHCARD_STATS_API_ENDPOINT = process.env.REACT_APP_FLASHCARD_STATS_API_ENDPOINT
export const FLASHCARD_SITE_NAME = process.env.REACT_APP_FLASHCARD_SITE_NAME
export const FLASHCARD_SHORTCUT_ENDPOINT = process.env.REACT_APP_FLASHCARD_SHORTCUT_ENDPOINT
export const SSO_ENDPOINT = process.env.REACT_APP_SSO_ENDPOINT

export const REDMINE = {
  ONE_WEEK_AGO: 1,
  ONE_MONTH_AGO: 2,
  NO_TIME_LIMIT: 3,
  START_DATE: 4,
  CLOSE_DATE: 5,
  STATUS_ID: 'status_id',

  IN_PROGRESS: 2,
  INVALID: 12,
  FIXED: 4,
  RESOLVED: 3,
  NEW: 1,
  CLOSED: 5,
  TO_VERIFY: 11,
  VERIFIED_FAILED: 31,

  INTENT_BUG: 9,
  SLOT_BUG: 8,
  QUERY: 16,
  EXPECTED_INTENT: 15,
  CURRENT_INTENT: 11,
  IMAGES: 25,

  PROJECT_CONFIG_OPT: 103, // project id 103 配置优化
  PROJECT_CHAT_AUTH: 96 // project id 96, PRD chat  续订授权
}

export const ISSUE_TYPE = {
  BUG: 0,
  TASK: 1
}

export const REFUSE_CONFIG_OPT_OPTIONS = [
  '只用营销',
  '到期客户',
  '客户自己配置',
  '客户表示不用配置',
  '其他'
]

export const REFUSE_CHAT_AUTH_OPTIONS = [
  '断约',
  '不用机器人',
  '已沟通伤害',
  '多次联系不上',
  '其他'
]

export const BASE_PAGINATION = {
  showQuickJumper: true,
  showTotal(total) {
    return `共 ${total} 项`
  }
}

export const SHOP_STATUS = {
  UNKNOWN: '未知',
  NEED_CONFIGURATION: '待实施配置',
  NEED_VERIFICATION: '待主管检测',
  VERIFIED : '主管已检测'
}

export const BUG_URL_PREFIX = 'http://prism-snapshot.oss-cn-shanghai.aliyuncs.com'

export const DATE_FORMATTER = 'YYYY-MM-DD'

export const REPLY_TYPE = {
  '1': '预定义',
  '2': '基础自定义',
  '3': '行业包',
  '4': '万能自定义'
}

export const FEEDBACK_NOT_MINE = '1'
export const FEEDBACK_DATA_ISSUE = '2'
export const FEEDBACK_SELLER_NICK_ISSUE = '3'
export const FEEDBACK_CUSTOMER_NAME_ISSUE = '4'
export const FEEDBACK_RANKING_ISSUE = '5'
export const FEEDBACK_MOCK_SHOP_DOMAIN_ISSUE = '7'
export const FEEDBACK_OTHER = '6'

export const FEEDBACK_OPTIONS = [
  {
    key: FEEDBACK_NOT_MINE,
    value: '不是我的客户'
  }, {
    key: FEEDBACK_DATA_ISSUE,
    value: '指标数据有误'
  }, {
    key: FEEDBACK_SELLER_NICK_ISSUE,
    value: '商家账号有误'
  }, {
    key: FEEDBACK_CUSTOMER_NAME_ISSUE,
    value: '客户名有误'
  }, {
    key: FEEDBACK_RANKING_ISSUE,
    value: '排名数据有误'
  }, {
    key: FEEDBACK_MOCK_SHOP_DOMAIN_ISSUE,
    value: '没有合适的模板店铺'
  }, {
    key: FEEDBACK_OTHER,
    value: '其他'
  }
]

export const STATS_TYPE= {
  STORE: "store",
  STORE_HIT: "store_hit",
  CUSTOMER: "customer_v2",
  CUSTOMER_HIT: "customer_hit_v2",
  STORE_ACCOUNT_USAGE: "store_account_usage",
  CUSTOMER_ACCOUNT_USAGE: "customer_account_usage"
}

export const STATS_TYPE_OPTIONS = [
  {
    key: '1',
    value: STATS_TYPE.STORE,
    label: '整体问答能力(店铺)'
  },
  {
    key: '2',
    value: STATS_TYPE.STORE_HIT,
    label: '细分问答能力(店铺)'
  },
  {
    key: '3',
    value: STATS_TYPE.CUSTOMER,
    label: '整体问答能力(客户)'
  },
  {
    key: '4',
    value: STATS_TYPE.CUSTOMER_HIT,
    label: '细分问答能力(客户)'
  },
  {
    key: '5',
    value: STATS_TYPE.STORE_ACCOUNT_USAGE,
    label: '账号使用率(店铺)'
  },
  {
    key: '6',
    value: STATS_TYPE.CUSTOMER_ACCOUNT_USAGE,
    label: '账号使用率(客户)'
  }
]
